import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import fastClick from 'fastclick' //解决移动端3点击屏幕00ms延迟
// import VConsole from 'vconsole'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import FullScreenLoading from "@tntlike/vue-fullscreen-loading";

import { download } from "@/utils/base";


Vue.use(ElementUI);
Vue.use(FullScreenLoading, {
    tips: "loading",
    fontColor: "#ffffff",
    backgroundColor: "#0B0B1496",
    fontSize: 18,
});
// Vue.use(new VConsole())

//echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$Echarts = echarts;

//使用文件别名引入
import 'styles/reset.css' //import './assets/styles/reset.css'
//自定义公共类
import 'styles/common.css'

Vue.config.productionTip = false

fastClick.attach(document.body) //解决移动端点击屏幕00ms延迟

Vue.prototype.download = download

Vue.prototype.msgError = function(msg) {
    this.$message({ showClose: true, message: msg, type: 'error' })
}

new Vue({
    router: Router,
    render: h => h(App),
}).$mount('#app')