import Vue from 'vue'
import VueRouter from 'vue-router'
// import CaseReport from '@/pages/caseReport/caseReport.vue';//个体报告

Vue.use(VueRouter)

/**
 * 注意:app.js加载的时候至少超过1mb的时候才会使用异步加载组件的方式,不然反而会降低项目的性能
 *
 * 一般情况下都是使用同步加载组件的方式
 */
const routes = [
    // {
    //   path: '/',
    //   name: 'CaseReport',
    //   component: CaseReport //这中引入方式是同步加载组件方式 加载第一个页面的时候app.js会把所有的页面js都加载完 再进入其他页面js不会再次加载
    //   // component: ()=>import('@/pages/categoryList/categoryList.vue')//异步加载组件: 每次进入不同的页面加载自己单独的js文件
    // },
    {
        path: '/lesson',
        name: 'lesson',
        component: resolve => require(['@/pages/lesson'], resolve)
    },
    {
        path: '/GroupReport',
        name: 'groupReport',
        component: resolve => require(['@/pages/groupReport'], resolve)
    },
    {
        path: '/PersonalReport',
        name: 'PersonalReport',
        component: resolve => require(['@/pages/personalReport'], resolve)
    },
    {
        path: '/CaseReport',
        name: 'CaseReport',
        component: resolve => require(['@/pages/caseReport'], resolve)
    },
    {
        path: '/TeamReport',
        name: 'TeamReport',
        component: resolve => require(['@/pages/teamReport'], resolve)
    },
    {
        path: '/WarnReport',
        name: 'WarnReport',
        component: resolve => require(['@/pages/warnReport'], resolve)
    },
    {
        path: '/caseReportOld',
        name: 'caseReportOld',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import('../pages/caseReportOld/index.vue')
    },
    {
        path: '/classReport',
        name: 'classReport',
        component: () =>
            import('../pages/classReport/index.vue')
    },
    {
        path: '/gradeReport',
        name: 'gradeReport',
        component: () =>
            import('../pages/gradeReport/index.vue')
    },
    {
        path: '/schoolReport',
        name: 'schoolReport',
        component: () =>
            import('../pages/schoolReport/index.vue')
    },
    {
        path: '/summary',
        name: 'Summary',
        component: () =>
            import('../pages/Summary/index.vue')
    }
    //   ,
    //   {
    //     path: '/detail/:id',
    //     name: 'detail',
    //     component: Detail
    //     // component: ()=>import('@/pages/detail/Detail.vue')//异步加载组件
    //   }
]
const router = new VueRouter({
    base: process.env.NODE_ENV === "production" ? process.env.VUE_APP_CONTEXT_PATH : "/",
    routes,
    // 让页面切换的时候始终在最顶部
    //  scrollBehavior(to, from, savedPosition){
    //     return {x:0,y:0}
    //  }
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})

export default router